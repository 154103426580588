import React, { useState, useEffect } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const PhoneCustomInput = (props) => {
  const [location, setlocation] = useState({});
  const [data, setData] = useState([]);
  const YOUR_MAPBOX_ACCESS_TOKEN =
    "pk.eyJ1IjoidGFsaGFiYmFzaSIsImEiOiJjbDFuZ3hxODkwMW4wM2JtZmozYmtoZ2xlIn0.STbVNEcchvBK5zg5m488pw";

  useEffect(() => {
    async function showPosition(pos) {
      const { latitude: lt, longitude: lg } = await pos.coords;
      setlocation({ ...location, lg, lt });
    }
    navigator.geolocation.getCurrentPosition(showPosition);
  }, []);
  useEffect(() => {
    if (!location || location === {}) {
      // console.log("eroor")
      return;
    }

    async function call() {
      await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${
          location?.lg ? location?.lg : "100.569649"
        },${
          location?.lt ? location?.lt : "	6.155672"
        }.json?access_token=${YOUR_MAPBOX_ACCESS_TOKEN}`
      )
        .then((data) => data.json())
        .then((data) => setData(data.features));
    }
    call();
  }, [location]);

  let flag = data[1]?.context?.pop()?.short_code;

  return (
    <>
      <PhoneInput
        placeholder="Phone Number*"
        country={flag}
        value={props.value}
        isValid={props.isValid}
        onChange={props.onChange}
        inputClass={props.inputClass ? props.inputClass : ""}
        //   phoneContact => setValues({ ...values, phoneContact })
        containerClass="phoneSelect"
      />
    </>
  );
};

export default PhoneCustomInput;
