import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import { Container, Row, Col, Button } from "react-bootstrap";
import { BsArrowLeft } from "@react-icons/all-files/bs/BsArrowLeft";
import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight";

import * as styles from "./WorkPortfolio.module.scss";

function Arrow(props) {
  const { first, onClick } = props;
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <BsArrowRight onClick={onClick} className={styles.portfolioArrowIcon} />
      </div>
    </div>
  );
}
function PrevArrow(props) {
  const { first, onClick } = props;
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <BsArrowLeft className={styles.portfolioArrowIcon} />{" "}
      </div>
    </div>
  );
}

const WorkPortfolio = ({ bgColor = "#f2f2f2", pt, data }) => {
  // const data = useStaticQuery(graphql`
  //   query WorkPortfolioItemsQuery {
  //     wpPage(slug: { eq: "acf-common" }) {
  //       commonFields {
  //         workPortfolioItems {
  //           name
  //           description
  //           slug
  //           image {
  //             localFile {
  //               childImageSharp {
  //                 gatsbyImageData(
  //                   width: 610
  //                   quality: 100
  //                   placeholder: BLURRED
  //                   formats: [AUTO, WEBP, AVIF]
  //                 )
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)
  // const { workPortfolioItems: items } = data.wpPage.commonFields
  const items = [];

  const settings = {
    dots: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    dotsClass: "button__bar",
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <section
      className="WorkPortfolio pb-5 pb-md-0"
      style={{ background: `${bgColor}`, paddingTop: pt }}
    >
      <Container>
        <Row>
          <Col md={6}></Col>
          <Col md={6}>
            <h2 className="main-heading-36">
              Work <span className="text-primary">Portfolio</span>
            </h2>
          </Col>
        </Row>
        <Slider {...settings}>
          {data?.map((item, i) => {
            // const image = getImage(item.image.mediaItemUrl);
            return (
              <div key={i}>
                <Row>
                  <Col md={6} className="mt-5 mt-md-0">
                    <img
                      src={item?.image?.mediaItemUrl}
                      alt={item.name}
                      loading="lazy"
                    />
                  </Col>
                  <Col md={6} className="pt-md-5  mt-md-5">
                    <p className="pt-md-3 text-20 simple-font-weight">
                      {item.name}
                    </p>
                    <div className=" my-md-4 pb-5">{item.description}</div>
                    <div>
                      {/* <a href={`https://invozone.com/portfolio/${item.slug}/`}> */}
                      <Button
                        className={"btn_black_border mb-5 mb-lg-0"}
                        disabled
                      >
                        View Portfolio
                      </Button>
                      {/* </a> */}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Slider>
      </Container>
    </section>
  );
};

export default WorkPortfolio;
