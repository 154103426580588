import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Nav, Navbar, Dropdown, NavLink, Row, Col } from "react-bootstrap";
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown";
import * as styles from "./Header.module.scss";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { MobileHeader } from ".";

import logo from "../../images/logo.png";
import phone from "../../images/ppc/email.svg";
import email from "../../images/ppc/phone.svg";

const Header = ({ bgChanged = true, showBanner }) => {
  const [navExpanded, setNavExpanded] = useState(false);
  const [renderMenu, setRenderMenu] = useState(false);
  const { width } = useWindowDimensions();
  // const data = useStaticQuery(graphql`
  //   query getMainMenuItems {
  //     wpPage(slug: { eq: "acf-common" }) {
  //       commonFields {
  //         mainMenuItems {
  //           name
  //           slug
  //           subMenuType
  //           subMenuItems {
  //             name
  //             slug
  //             icon {
  //               sourceUrl
  //               localFile {
  //                 childImageSharp {
  //                   gatsbyImageData(
  //                     layout: FIXED
  //                     width: 40
  //                     formats: [AUTO, WEBP]
  //                   )
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  const items = [
    {
      name: "About Us",
      slug: "about-us",
      subMenuType: "none",
      // subMenuItems: [],
    },
  ];

  // const { mainMenuItems: items } = data?.wpPage?.commonFields;
  const [transparent, setTransparent] = useState(true);
  const isMobile = width <= 720;

  const handleScroll = (event) => {
    !isMobile && setNavExpanded(false);
    if (window.scrollY < 77) {
      return setTransparent(true);
    } else if (window.scrollY > 77) {
      return setTransparent(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    setRenderMenu(false);
  }, []);

  const clickHandler = (name) => {
    setRenderMenu(!renderMenu);
    // setAnimate(renderMenu ? false : name)
  };

  const hoverHandler = (name) => {
    if (isMobile) {
      return setRenderMenu(false);
    }
    setRenderMenu(true);
    // setAnimate(name)
  };

  return (
    <header>
      {/* {isMobile && (
        <div className={styles.mobileHeader}>
          <div className={styles.content}>
            <div className={styles.box}>
              <div className={styles.boxConent}>
                <h5>Mail Address</h5>
                <a href="mailto:info@Tv2u.pk" target="_blank">
                  Info@Tv2u.pk
                </a>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.boxConent}>
                <h5>Phone Number</h5>
                <a href="tel:+923120586610">+92 (312) 0586610</a>
              </div>
            </div>
          </div>
        </div>
      )} */}

      <Navbar
        bg={`${
          transparent && bgChanged && !navExpanded ? "" : "primary"
        } fixed-menu-bar`}
        expand="lg"
        onToggle={() => setNavExpanded(!navExpanded)}
        expanded={navExpanded}
      >
        <div className={styles.headerContainer}>
          <Navbar.Brand as={Link} to="/" className={`w-auto`}>
            <div style={{ maxWidth: "100px" }}>
              <img src={logo} className="img-fluid" alt="logo-head" />
            </div>
          </Navbar.Brand>
          {width > 991 ? (
            <>
              {/* <Navbar.Toggle aria-controls="main-navbar-nav" /> */}
              <Navbar.Collapse id="main-navbar-nav">
                <Nav className="ml-auto">
                  {items.map((item) => {
                    const { name, slug, subMenuType: type, subMenuItems: subItems } = item // prettier-ignore
                    if (type === "none") {
                      return (
                        <Nav.Link
                          as={Link}
                          to={`/${slug}/`}
                          key={`${name}-nv`}
                          id={`header_${slug}_btn_gt`}
                        >
                          {name}
                        </Nav.Link>
                      );
                    } else if (type === "list") {
                      return (
                        <Dropdown
                          key={`${name}-ndp`}
                          alignRight
                          className="megaMenuColumnbox"
                        >
                          <Dropdown.Toggle
                            as={NavLink}
                            href={"#"}
                            className="text-white"
                            onClick={() => clickHandler(name)}
                            onMouseEnter={() => hoverHandler(name)}
                          >
                            {name}
                            <FaCaretDown
                              className={styles.alignHeaderDropIcon}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            renderOnMount={renderMenu}
                            className={`ml-3`}
                          >
                            <Row className={styles.dropMenu}>
                              <Col xs={12}>
                                {subItems?.map((subItem) => (
                                  <div key={`${subItem.name}-ndpi`}>
                                    <Dropdown.Item
                                      as={Link}
                                      to={`/${subItem.slug}/`}
                                      className="p-1 bg-white"
                                    >
                                      <Row className={styles.menuItem}>
                                        <Col xs={3} md={3}>
                                          {subItem?.icon?.localFile
                                            ?.childImageSharp ? (
                                            <GatsbyImage
                                              image={getImage(
                                                subItem.icon.localFile
                                              )}
                                              alt={subItem.name}
                                              width={43}
                                            />
                                          ) : (
                                            <img
                                              src={subItem?.icon?.sourceUrl}
                                              alt={subItem.name}
                                              width={43}
                                              loading="lazy"
                                            />
                                          )}
                                        </Col>
                                        <Col
                                          xs={9}
                                          md={9}
                                          className="d-flex align-items-center "
                                        >
                                          <div className="d-flex ml-2">
                                            {subItem.name}
                                          </div>
                                        </Col>
                                      </Row>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                  </div>
                                ))}
                              </Col>
                            </Row>
                          </Dropdown.Menu>
                        </Dropdown>
                      );
                    } else if (
                      type === "grid" &&
                      name.toLowerCase() !== "portfolio"
                    ) {
                      return (
                        <Dropdown
                          key={`${name}-ndp`}
                          alignRight
                          className="megaMenuColumnbox"
                          // autoClose="outside"
                        >
                          <Dropdown.Toggle
                            as={NavLink}
                            href={`/${slug}/`}
                            className="text-white"
                            id="dropdown-autoclose-outside"
                            onMouseEnter={() => hoverHandler(name)}
                          >
                            {name}
                            <FaCaretDown
                              className={styles.alignHeaderDropIcon}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu renderOnMount={renderMenu}>
                            <Row
                              className={[
                                styles.dropdownMulticolMenu,
                                subItems.length > 8
                                  ? styles.column3
                                  : styles.column2,
                              ]}
                              style={{ maxHeight: 400, overflowY: "auto" }}
                            >
                              {subItems?.map((subItem) => (
                                <Col
                                  lg={4}
                                  className={styles.megaMenuColumn}
                                  key={`${subItem.name}-ndpi`}
                                >
                                  <Dropdown.Item
                                    as={Link}
                                    to={
                                      slug === "portfolio"
                                        ? `/${slug}/${subItem.slug}/`
                                        : `/${subItem.slug}/`
                                    }
                                    className={`container justify-content-start ${styles.megaMenuLink}`}
                                  >
                                    <img
                                      src={subItem?.icon?.sourceUrl}
                                      alt={subItem.name}
                                      width={43}
                                      loading="lazy"
                                      style={{ float: "left" }}
                                    />
                                    <div className="d-flex ml-2">
                                      {subItem.name}
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                </Col>
                              ))}
                              <Col
                                lg={12}
                                className={`justify-content-end d-flex pt-2 pt-md-1`}
                              >
                                <Link to={`/${slug}/`}>
                                  <button
                                    className="btn_black_border font-weight-normal"
                                    style={{ fontSize: 13 }}
                                  >
                                    View All
                                  </button>
                                </Link>
                              </Col>
                            </Row>
                          </Dropdown.Menu>
                        </Dropdown>
                      );
                    }
                  })}

                  <Nav.Link
                    as={Link}
                    to="/contact-us/"
                    className="text-white"
                    // className={`btn_black_border font-weight-normal`}
                    id="header_contact_us_btn_gt"
                  >
                    Contact Us
                  </Nav.Link>
                </Nav>
                <div className={styles.content}>
                  <div className={styles.box}>
                    <img src={email} alt="email" />
                    <div className={styles.boxConent}>
                      <h5>Mail Address</h5>
                      <a
                        href="mailto:info@tv2uasia.com "
                        target="_blank"
                        rel="noreferrer"
                      >
                        info@tv2uasia.com
                      </a>
                    </div>
                  </div>
                  <div className={styles.box}>
                    <img src={phone} alt="phone" />
                    <div className={styles.boxConent}>
                      <h5>Phone Number</h5>
                      <a href="tel:+601123234533">+60-11-2323-4533</a>
                    </div>
                  </div>
                </div>
              </Navbar.Collapse>
            </>
          ) : (
            <MobileHeader items={items} />
          )}
        </div>
      </Navbar>

      {/* {show ? (
        <div className={styles.leapBanner}>
          <div className={styles.leap}>
            <IoMdClose className={styles.cross} onClick={() => isShow(false)} />
            <Link to="/meet-Tv2u-at-mwc-barcelona/">
              <img
                src={LeapBanner}
                className={styles.leapBanner}
                alt="banner"
              />
            </Link>
          </div>
        </div>
      ) : null} */}
    </header>
  );
};

export default Header;
