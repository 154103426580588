import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import Slider from "react-slick";

import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaSkype } from "@react-icons/all-files/fa/FaSkype";
import { useStaticQuery, graphql } from "gatsby";

import * as styles from "./ContactSales.module.scss";

import upWork from "../../images/accolades/upwork.png";
import goodFirms from "../../images/cta/Top_Web_Developers.png";
import appFutura from "../../images/accolades/appfutura.png";
import develop4u from "../../images/accolades/develop4u.png";
import topDevelopers from "../../images/accolades/top-developers.png";
import itFirms from "../../images/accolades/itfirms.png";
import glassDoor from "../../images/cta/Glassdoor.png";
// import topDevelopers1 from '../../images/cta/Top_Developers.png';
import agencyVista from "../../images/cta/agency_vista.png";
import topFirms from "../../images/accolades/top-firms.png";
import clutch2021 from "../../images/accolades/clutch-2021.png";
import wadline from "../../images/accolades/wadline.png";
import designrushNew from "../../images/accolades/designrush-new.png";
import techreviewer from "../../images/accolades/techreviewer.png";
import trustpilot from "../../images/accolades/trustpilot.png";
import upCity from "../../images/cta/upCity.png";
import selectedFirms from "../../images/cta/selectedfirms.png";
import mobileApp from "../../images/cta/mobiledaily.png";
import bg_image from "../../images/bg-images/contact-bg.svg";
import topCompany from "../../images/accolades/topCompany.png";
import saleHeadImg from "../../images/waqas.png";
const ContactSalesForm = React.lazy(() => import("./ContactSalesForm"));

const ContactSales = ({ bgColor, data }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     wpPage(slug: { eq: "acf-common" }) {
  //       commonFields {
  //         reliableSds {
  //           title
  //           value
  //         }
  //       }
  //     }
  //   }
  // `)

  const reliableSds = data;
  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const isSSR = typeof window === "undefined";

  const formSection = (
    <div className={`${styles.card} px-md-5`}>
      {!isSSR && (
        <React.Suspense
          fallback={
            <div className="text-center">
              <Spinner
                variant="light"
                size="sm"
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          }
        >
          <ContactSalesForm />
        </React.Suspense>
      )}
    </div>
  );

  return (
    <section
      className={styles.section}
      // style={{ backgroundColor: `${bgColor}` }}
    >
      <Container>
        <div
          style={{
            minWidth: "500px",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 0,
          }}
        >
          <img src={bg_image} loading="lazy" alt="sales" width="100%" />
        </div>
        <Row className="justify-content-center" style={{ zIndex: 1 }}>
          <Col md={12} lg={7} className="text-center">
            <h2 className="main-heading-36">
              <span className="text-primary">
                Let Us Know What You’re Looking for,{" "}
              </span>{" "}
              <br />
              We’ll Build it for You
            </h2>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={6}>
            <h4 className="pt-2 pt-md-4 ">Talk to our Sales Team</h4>
            <div className={styles.personCard}>
              <div>
                <img
                  src={saleHeadImg}
                  alt="Asfand Yarr"
                  placeholder="blurred"
                  quality={100}
                  width={131}
                  height={128}
                  loading="lazy"
                />
              </div>
              <div className="ml-4 pt-4">
                <p className="text-20 simple-font-weight">Waqqas Rana</p>
                <div>Head of Sales</div>
                <div className={styles.contactIcons}>
                  <a href="mailto:waqqas@invozone.com" aria-label="Email him">
                    <FaEnvelope color="#f14336" />
                  </a>
                  <a
                    href="https://www.linkedin.com/in/waqqas-ahmed-50886920/"
                    aria-label="View his LinkedIn profile"
                    rel="noreferrer noopener nofollow"
                  >
                    <FaLinkedin color="#0073B1" />
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=tel:+923120586610&text=Lets Discuss Project"
                    aria-label="Contact him on WhatsApp"
                    target="_blank"
                  >
                    <FaWhatsapp color="#3dbc4d" />
                  </a>
                  <a
                    href="skype:b5e610fa37cde4be?call"
                    aria-label="Call him on Skype"
                    target="_blank"
                  >
                    <FaSkype color="#03a9f4" />
                  </a>
                </div>
              </div>
            </div>

            <Row className={"pt-5 pb-5"}>
              {reliableSds?.map((item) => (
                <Col xs={6} lg={3} key={item.value}>
                  <p className="main-heading-36 simple-font-weight">
                    {item?.value}
                  </p>
                  <p>{item?.title}</p>
                </Col>
              ))}
            </Row>

            <div className="accslider">
              <Slider {...settings} className="pl-3 pl-md-0">
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://www.trustpilot.com/review/invozone.com"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    <img
                      src={trustpilot}
                      alt="Trust Pilot"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://www.upwork.com/ag/invozone/"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    {/* <StaticImage
                      src="../../images/accolades/upwork.png"
                      alt="Upwork Top Rated"
                      placeholder="blurred"
                      loading='lazy'

                    /> */}
                    <img
                      src={upWork}
                      alt="Upwork Top Rated"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://www.goodfirms.co/company/invozone"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {/* <StaticImage
                      src="../../images/cta/Top_Web_Developers.png"
                      alt="GoodFirms - Top Software Development Company"
                      placeholder="blurred"
                      loading='lazy'

                    /> */}
                    <img
                      src={goodFirms}
                      alt="GoodFirms - Top Software Development Company"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://www.appfutura.com/companies/invozone"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {/* <StaticImage
                      src="../../images/accolades/appfutura.png"
                      alt="AppFutura"
                      // placeholder="blurred"
                      loading='lazy'

                    /> */}
                    <img
                      src={appFutura}
                      alt="AppFutura"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://develop4u.co/company/invozone"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    <img
                      src={develop4u}
                      alt="Developers 4 U"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://www.topdevelopers.co/profile/invozone"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={topDevelopers}
                      alt="Top Developers"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://www.itfirms.co/top-mobile-app-development-companies-in-canada/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={itFirms}
                      alt="ITFirms"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>

                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://www.glassdoor.ca/Overview/Working-at-InvoZone-EI_IE3297931.11,19.htm"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={glassDoor}
                      alt="Software Devleopment company"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>

                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://agencyvista.com/agency/invozone/marketing-agency-toronto-ca"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={agencyVista}
                      alt="review"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://topfirms.co/companies/software-development/world"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={topFirms}
                      alt="Top Firms"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://superbcompanies.com/categories/web-app-development-companies/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={topCompany}
                      alt="top development company"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="p-2">
                  <a
                    href="https://clutch.co/profile/invozone"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                  >
                    <img
                      src={clutch2021}
                      alt="Clutch 2021 - Top Web Developers in Canada"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://wadline.com/invozone"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={wadline}
                      alt="wadline"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://www.designrush.com/agency/ar-vr"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={designrushNew}
                      alt="designrush"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://techreviewer.co/companies/invozone"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={techreviewer}
                      alt="techreviewer"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://upcity.com/profiles/invozone"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={upCity}
                      alt="techreviewer"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://selectedfirms.co/companies/ecommerce-development/usa"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={selectedFirms}
                      alt="eCommerce site"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://www.mobileappdaily.com/reports/fastest-growing-app-development-companies"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={mobileApp}
                      alt="mobile app"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
                <div className="pt-2 pr-2 pb-2 pl-0">
                  <a
                    href="https://superbcompanies.com/categories/web-app-development-companies/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src={topCompany}
                      alt="top dev companies"
                      placeholder="blurred"
                      loading="lazy"
                      width={125}
                      height={125}
                    />
                  </a>
                </div>
              </Slider>
            </div>
          </Col>
          <Col md={12} lg={6} className="justify-lg-content-end d-flex">
            <div>
              <p className="pt-4 pb-1">
                We are passionate about delivering great software and services.
              </p>
              {formSection}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactSales;
