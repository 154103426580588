import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";

import { FaAngleDown } from "@react-icons/all-files/fa/FaAngleDown";
import { Dropdown } from "react-bootstrap";
import * as styles from "./mobileHeader.module.scss";
import { useLocation } from "@reach/router";
const Header = ({ items }) => {
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  const [subMenu, setSubMenu] = useState({
    show: false,
    data: "",
  });
  const ref = useRef(null);

  const { data } = subMenu;

  const handleClicked = () => {
    clicked && setSubMenu({ ...subMenu, show: !clicked });

    setClicked(!clicked);
  };
  useEffect(() => {
    if (ref.current.classList.contains("activeNav")) {
      // Disable

      document.body.style.overflow = "hidden";
    } else {
      // Enable scroll

      document.body.style.overflow = "auto";
    }
  }, [location, clicked]);

  const contactBtn = (
    <div className={styles.btnContainer}>
      <button>
        <Link to="/contact-us">Contact us</Link>
      </button>
    </div>
  );

  return (
    <>
      <div
        className={`${styles.nav_menu}  ${clicked && `activeNav`}`}
        ref={ref}
      >
        <div className={styles.menuContainer}>
          {items?.map((item) =>
            item?.subMenuType === "none" ? (
              <Link to={`/${item?.slug}/`} key={item?.name}>
                <div key={item?.name} className={styles.nav_link}>
                  {item?.name}
                </div>
              </Link>
            ) : item?.subMenuType === "grid" &&
              item?.name?.toLowerCase() !== "portfolio" ? (
              <Dropdown
                key={item?.name}
                onClick={() =>
                  setSubMenu({
                    show: false,
                    data: item,
                  })
                }
              >
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className={styles.nav_link}
                >
                  {item?.name}
                  <FaAngleDown />
                </Dropdown.Toggle>

                <Dropdown.Menu className={` ${styles.menuDropdown}`}>
                  {data?.subMenuItems?.map((subItem) => (
                    <Link
                      key={subItem?.name}
                      className={styles.sub_nav_link}
                      to={
                        data?.slug === "portfolio"
                          ? `/${data?.slug}/${subItem.slug}/`
                          : `/${subItem.slug}/`
                      }
                    >
                      <img
                        src={subItem?.icon?.sourceUrl}
                        alt={subItem.name}
                        width={30}
                        height={30}
                        loading="lazy"
                      />
                      <span className="pl-3">{subItem?.name}</span>
                    </Link>
                  ))}
                  {data?.subMenuType === "grid" && (
                    <Link
                      to={`/${data?.slug}`}
                      className={`d-flex justify-content-center`}
                      style={{ fontSize: 13 }}
                    >
                      <p className={`btn_black_border w-75 mt-3 p-1`}>
                        View All
                      </p>
                    </Link>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            ) : item?.subMenuType === "list" ? (
              <>
                <Dropdown
                  key={item?.name}
                  onClick={() => setSubMenu({ show: false, data: item })}
                >
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className={styles.nav_link}
                  >
                    {item?.name}
                    <FaAngleDown />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className={` ${styles.menuDropdown}`}>
                    {data?.subMenuItems?.map((subItem) => (
                      <Link
                        key={subItem?.name}
                        className={styles.sub_nav_link}
                        to={
                          data?.slug === "portfolio"
                            ? `/${data?.slug}/${subItem.slug}/`
                            : `/${subItem.slug}/`
                        }
                      >
                        <img
                          src={subItem?.icon?.sourceUrl}
                          alt={subItem.name}
                          width={30}
                          height={30}
                          loading="lazy"
                        />
                        <span className="pl-3">{subItem?.name}</span>
                      </Link>
                    ))}
                    {data?.subMenuType === "grid" && (
                      <Link
                        to={`/${data?.slug}`}
                        className="btn_black_border font-weight-normal"
                        style={{ fontSize: 13 }}
                      >
                        View All
                      </Link>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              ""
            )
          )}
        </div>
        {contactBtn}
      </div>
      <div
        className={`${styles.hamburger} ${clicked && styles.active}`}
        onClick={handleClicked}
      >
        <div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};
export default Header;
