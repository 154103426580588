// range(start, stop, step) util
export const range = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

// Calculate time to read a post
const countWords = (str) => str.trim().split(/\s+/).length;
export const getTimeToRead = (str) => Math.ceil(countWords(str) / 200);

// Convert to local date string e.g. November 16, 2021
export const getDateStr = (date) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options);
};

// Encode form values to x-www-form-urlencoded format
export const encodeToUrl = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

// Encode form values to FormData object
export const encodeToFormData = (dataForm, isMultiPart = false) => {
  // console.log(dataForm);
  const formData = new FormData();
  const data = {};
  for (const [key, value] of Object.entries(dataForm)) {
    if (isMultiPart) {
      if (value.type) {
        console.log(value, value.name);
        // files.forEach((file) => {
        formData.append(`files.${key}`, value, value.name);
        // });
      } else {
        // formData.append(key, value);
        data[key] = value;
      }
    } else {
      formData.append(key, value);
    }
  }
  isMultiPart && formData.append("data", JSON.stringify(data));
  console.log("Formssss", [...formData]);

  return formData;
};

// Test if the given string is a validated email address
export const isEmail = (str) => {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return pattern.test(str);
};

export const stringTruncate = function (str, length) {
  const dots = str.length > length ? "..." : "";
  return str.substring(0, length) + dots;
};
// Recaptcha Google keys
export const SITE_KEY = "6LcLCIkfAAAAAOEoeFWxcnWywui6uqzXwDBPHWSf";
export const SECTION_KEY = "6LcLCIkfAAAAAEv57pTOgQdVwCJGCa-yuNcxqSOg";
export const GOOGLE_API_KEY = "AIzaSyClxP8L6cR8rw0qxVORmBeJSgB7H8FXhrc";
