import React from "react";
import { Icon } from "@iconify/react";
// import locationIcon from "@iconify/icons-r";
import GoogleMapReact from "google-map-react";
import "./mapp.scss";
import { GOOGLE_API_KEY } from "../../utils";
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";

const AnyReactComponent = ({ text }) => (
  <div className="d-flex align-items-center flex-column justify-content-center">
    <FaMapMarkerAlt style={{ fontSize: "30px", marginBottom: "2px" }} />
    {text}
  </div>
);

const waterStyle = [
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#4BB4F5",
      },
    ],
  },
];

const Map = ({ location, zoomLevel }) => (
  <div className="map mt-5">
    <div className="google-map">
      <GoogleMapReact
        options={{ styles: waterStyle, disableDefaultUI: true }}
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <AnyReactComponent
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </div>
  </div>
);

export default Map;
