import React from "react";

import { Header, Footer } from "../components/common";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";
import * as styles from "./mainLayout.module.scss";

const MainLayout = ({ children, bgChanged = true }) => {
  return (
    <React.Fragment>
      <Header bgChanged={bgChanged} />
      <main>
        {children}
        {/* <MwcNewsletter /> */}
      </main>
      <Footer />

      <a
        href="https://api.whatsapp.com/send?phone=+923120586610&text=Lets Discuss Project"
        aria-label="Contact him on WhatsApp"
        target="_blank"
        className={styles.float}
        title="Whatsapp chat"
      >
        <FaWhatsapp />
      </a>
    </React.Fragment>
  );
};

export default MainLayout;
