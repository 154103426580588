import React, { useState } from "react";
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { FaPaperPlane } from "@react-icons/all-files/fa/FaPaperPlane";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { FaExclamationCircle } from "@react-icons/all-files/fa/FaExclamationCircle";

import * as styles from "./Newsletter.module.scss";

const Mailchimp = (props) => {
  const [email, setEmail] = useState("");
  const [response, setResponse] = useState("");
  const [loader, isLoader] = useState(null);
  const [status, setStatus] = useState({
    statuss: null,
    value: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    isLoader(true);
    addToMailchimp(email)
      .then(({ msg, result }) => {
        if (result !== "success") {
          setStatus({ ...status, statuss: true, value: result });
          setResponse(msg);
          isLoader(false);
        }
        setResponse(msg);
        setEmail("");
        setStatus({ ...status, statuss: false, value: result });

        isLoader(false);
      })
      .catch((err) => {
        setStatus({ ...status, statuss: true, value: "error" });
        setResponse(" Oops! Something went wrong while submitting the form.");
        isLoader(false);
      });
  };

  return (
    <>
      <Form name="Newsletter Form" method="POST" onSubmit={handleSubmit}>
        <InputGroup className={props.classs}>
          <FormControl
            type="email"
            aria-label="Email"
            placeholder={props.placeholder || "Your Email"}
            aria-describedby="Newsletter"
            className={styles.leftRound}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {props.classs
            ? status.value === "success" && (
                <div
                  className="mt-1 small erroSize"
                  style={{ color: "#00ff7f" }}
                >
                  <FaCheckCircle color="#00ff7f" /> You have successfully
                  subscribed our Newsletter.
                </div>
              )
            : null}
          {props.classs
            ? status.value === "error" && (
                <div
                  className="mt-1 small erroSize"
                  style={{ color: "#ff3800" }}
                >
                  <FaExclamationCircle color="#ff3800" />
                  {email?.trim() === "" ? "This field is required!" : response}
                </div>
              )
            : null}
          <InputGroup.Append>
            <Button
              type="submit"
              // variant="primary"
              className="px-3"
              style={{
                borderTopRightRadius: "0.75rem",
                borderBottomRightRadius: "0.75rem",
              }}
              aria-label="Send"
              id="footer_newsletter_btn_ga"
            >
              {loader && (
                <Spinner
                  variant="light"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {loader === true ? null : props.classs ? (
                "Subscribe"
              ) : (
                <FaPaperPlane size={20} />
              )}
            </Button>
          </InputGroup.Append>
        </InputGroup>

        {props.classs
          ? null
          : status.value === "success" && (
              <div className="mt-1 small" style={{ color: "#00ff7f" }}>
                <FaCheckCircle color="#00ff7f" /> You have successfully
                subscribed our Newsletter.
              </div>
            )}
        {props.classs
          ? null
          : status.value === "error" && (
              <div className="mt-1 small" style={{ color: "#ff3800" }}>
                <FaExclamationCircle color="#ff3800" />
                {email?.trim() === "" ? "This field is required!" : response}
              </div>
            )}
      </Form>
    </>
  );
};
export default Mailchimp;
