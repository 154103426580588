import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Spinner } from "react-bootstrap";

// import { FaEnvelopeOpen } from "@react-icons/all-files/fa/FaEnvelopeOpen"
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import caFlasgs from "../../images/icons/Email.svg";

import caFlag from "../../images/icons/Canada_Flag.svg";
import usaFlag from "../../images/icons/USA_Flag.svg";
import myFlag from "../../images/icons/Malaysia_Flag.svg";
import pkFlag from "../../images/icons/Pakistan_Flag.svg";
import singapore from "../../images/singapore.png";
import power from "../../images/powererd.svg";
// import caFlasgs from "../../images/icons/Email.svg"
// import myFlag from "../../images/icons/Malaysia_Flag.svg"
// import pkFlag from "../../images/icons/Pakistan_Flag.svg"
// import usaFlag from "../../images/icons/USA_Flag.svg"
// import LeapBanner from "../../images/banner/mwc.jpg"
import logo from "../../images/TV22.png";
import msc from "../../images/msc.png";
// const Newsletter = React.lazy(() => import("./Newsletter"));

import * as styles from "./Footer.module.scss";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Mailchimp from "./Mailchimpp";

const Footer = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;

  const isSSR = typeof window === "undefined";

  return (
    <footer className={styles.footer}>
      <Container>
        <Row className="py-5 align-items-center justify-content-center">
          <Col lg={2} md={6}>
            {/* <StaticImage
              src="../../images/logo.svg"
              alt="Tv2u"
              placeholder="blurred"
              layout="fixed"
              height={60}
              loading="lazy"
            /> */}
            <div style={{ maxWidth: "130px" }}>
              <img src={logo} className="img-fluid" />
            </div>
          </Col>
          <Col lg={2} md={6}>
            {/* <StaticImage
              src="../../images/logo.svg"
              alt="Tv2u"
              placeholder="blurred"
              layout="fixed"
              height={60}
              loading="lazy"
            /> */}
            <div style={{ maxWidth: "75px" }}>
              <img src={msc} className="img-fluid" />
            </div>
          </Col>
          <Col lg={4} md={6} className="mt-5 mt-lg-0">
            <div style={{ float: !isMobile && "left" }}>
              <div className="h5">
                <img
                  src={caFlasgs}
                  width={24}
                  alt="ca flag"
                  className="mr-2 newadjustment"
                  loading="lazy"
                />
                Email Us
              </div>
              <div>
                {!isMobile ? (
                  <>
                    <a
                      href="mailto:sales@tv2uasia.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      sales@tv2uasia.com
                    </a>
                    <span className="px-2">|</span>
                    <a
                      href="mailto:info@tv2uasia.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@tv2uasia.com
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      href="mailto:sales@tv2uasia.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      sales@tv2uasia.com
                    </a>
                    <div>
                      <a
                        href="mailto:info@tv2uasia.com "
                        target="_blank"
                        rel="noreferrer"
                      >
                        info@tv2uasia.com
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} className="mt-5 mt-lg-0">
            {!isSSR && (
              <React.Suspense
                fallback={
                  <div className="text-center">
                    <Spinner
                      variant="light"
                      size="sm"
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                }
              >
                {/* <Newsletter /> */}
                <Mailchimp />
              </React.Suspense>
            )}
          </Col>
        </Row>
        <div className={styles.divider} />

        <Row
          className="pt-5 align-items-center"
          style={{ paddingBottom: "64px" }}
        >
          <Col md={4}>
            <div className="mb-4">
              <div className="h5">
                <img
                  src={pkFlag}
                  width={28}
                  alt="pk flag"
                  className="mr-2"
                  loading="lazy"
                />
                Pakistan
              </div>
              <div className="address">
                <a
                  href={"https://goo.gl/maps/mW5Qa816h7KRHqMJ7"}
                  target="_blank"
                >
                  {" "}
                  605, Block H3, Opposite to Expo Center Gate No 1, Johar Town
                  Lahore{" "}
                </a>
              </div>
              <div>
                <a href="tel:+923177711171">+92-317-77-111-71</a>
              </div>
            </div>

            <div className="mb-4">
              <div className="h5">
                <img
                  src={caFlag}
                  width={28}
                  alt="ca flag"
                  className="mr-2"
                  loading="lazy"
                />
                Canada
              </div>
              <div className="address">
                <a
                  href={"https://goo.gl/maps/fsRZLEcFzqehP2rB8"}
                  target="_blank"
                >
                  220 Duncan Mill Road, Toronto, Ontario M3B 3J5
                </a>
              </div>
              <div>
                <a href="tel:+1647548-7866">+1 (647) 548-7866</a>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <div className="h5">
                <img
                  src={myFlag}
                  width={28}
                  alt="my flag"
                  className="mr-2"
                  loading="lazy"
                />
                Malaysia
              </div>
              <div className="address">
                <a
                  href={"https://goo.gl/maps/tkjTYhNrvsFG1JAN7"}
                  target="_blank"
                >
                  Tower A, Level 25, The Vertical, Unit 10, Jalan Kerinchi,
                  Bangsar South, 59200 Kuala Lumpur
                </a>
              </div>
              <div>
                <a href="tel:+601123234533">+60-11-2323-4533</a>
              </div>
            </div>
            <div className="mb-4">
              <div className="h5">
                <img
                  src={singapore}
                  width={28}
                  alt="singapore"
                  className="mr-2"
                  loading="lazy"
                />
                Singapore{" "}
              </div>
              <div className="address">
                <a
                  href={"https://goo.gl/maps/FHB6Wh8F3VbWgUiu9"}
                  target="_blank"
                >
                  400 Orchard Road , Orchard Tower,Singapore 23887
                </a>
              </div>
              <div>
                {/* <a href="tel:+1647548-7866">+1 (647) 548-7866</a> */}
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className={styles.flags}>
              <div className={styles.box}>
                <img src={myFlag} alt="malaysia" />
                <h4>Malaysia</h4>
              </div>

              <div className={styles.box}>
                <img src={caFlag} alt="canada" />
                <h4>Canada</h4>
              </div>
              <div className={styles.box}>
                <img src={singapore} alt="Usa" />
                <h4>Singapore</h4>
              </div>
              <div className={styles.box}>
                <img src={pkFlag} alt="pakistan" />
                <h4>Pakistan</h4>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bg-primary text-light py-4">
        <Container>
          <div className="d-flex justify-content-between text-center flex-column flex-md-row">
            <div>
              &copy; {new Date().getFullYear()} All Rights Reserved By &nbsp;
              <Link to="/">Tv2u</Link>
            </div>
            <div>
              <img
                src={power}
                alt={"powered by Invozone"}
                style={{ minWidth: "200px" }}
              />
            </div>
            {/* <div className={styles.followUs}>
              <a
                href="https://www.facebook.com/InvoZone1/?_rdc=2&amp;_rdr"
                target="_blank"
                rel="noreferrer"
                aria-label="Like us on Facebook"
                className="btn btn-primary ml-md-3"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://twitter.com/invozone?lang=en"
                target="_blank"
                rel="noreferrer"
                aria-label="Follow us on Twitter"
                className="btn btn-primary ml-md-3"
              >
                <FaTwitter />
              </a>
              <a
                href="https://github.com/InvoZone"
                target="_blank"
                rel="noreferrer"
                aria-label="Follow us on GitHub"
                className="btn btn-primary ml-md-3"
              >
                <FaGithub />
              </a>
              <a
                href="https://www.instagram.com/invozone/?hl=en"
                target="_blank"
                rel="noreferrer"
                aria-label="Follow us on Instagram"
                className="btn btn-primary ml-md-3"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/invozone"
                target="_blank"
                rel="noreferrer"
                aria-label="Follow us on LinkedIn"
                className="btn btn-primary ml-md-3"
              >
                <FaLinkedin />
              </a>
              <a
                href="https://www.youtube.com/channel/UCamPgA6MxGvg2T38QY1wOSQ"
                target="_blank"
                rel="noreferrer"
                aria-label="Subscribe us on YouTube"
                className="btn btn-primary ml-md-3"
              >
                <FaYoutube />
              </a>
            </div> */}
            {/* <div className={styles.footerNav}>
              <Link to="/about-us/">About Us</Link>
              <Link to="/blog/">Blog</Link>
              <Link to="/industries/">Industries</Link>
              <Link to="/portfolio/">Portfolio</Link>
              <Link to="/sitemap/">Sitemap</Link>
              <Link to="/privacy-policy/">Privacy Policy</Link>
              <Link to="/terms-conditions/">Terms &amp; Conditions</Link>
            </div> */}
          </div>
        </Container>
      </div>
      {/* {
        <Link to="/meet-invozone-at-mwc-barcelona/">
          <img src={LeapBanner} className={styles.leapBanner} alt="banner" />
        </Link>
      } */}
    </footer>
  );
};

export default Footer;
